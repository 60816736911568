import { ApolloProvider } from '@apollo/client'
import { createRoot } from 'react-dom/client'
import { client, t } from 'shared'
import { CookieBannerLink, CookieBanner } from 'islands/cookie_banner/cookie_banner'

function renderCookieBanner() {
  const domNode = document.getElementById('cookie-banner')

  if (domNode) {
    const root = createRoot(domNode)

    root.render(
      <ApolloProvider client={client}>
        <CookieBannerLink content={t('link_titles.cookie_settings')} />
        <CookieBanner />
      </ApolloProvider>
    )
  }
}

document.addEventListener('htmx:afterSettle', renderCookieBanner)
document.addEventListener('DOMContentLoaded', renderCookieBanner)
window.addEventListener('htmx:historyRestore', renderCookieBanner)

import React, { useEffect, useRef, useState } from 'react'
import posthog from 'posthog-js'
import useClickAway from 'react-use/lib/useClickAway'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import { LogoCompact, t, userAgentIsBot, I18nHtml, isMobile } from 'shared'
import Cookies, { CookieChangeListener } from 'universal-cookie'
import styles from 'shared/components/cookie_banner/cookie_banner.module.css'
import classNames from 'classnames'
import { useBrowserEventMutation } from 'gql'
import useGoogleTagManager from 'shared/components/cookie_banner/use_google_tag_manager'
import { CookieBannerModal } from './cookie_banner_modal'

const cookies = new Cookies()

// Cookie has been renamed to force the banner again on all users
const trackingCookieName = 'goodcrowd-tracking-accepted'

export const CookieBanner = () => {
  const [sendBrowserEvent] = useBrowserEventMutation()
  const [trackingChoice, setTrackingChoice] = useState(cookies.get(trackingCookieName))
  const [informationRequested, setInformationRequested] = useState(false)

  const refCookieBanner = useRef<HTMLDivElement | null>(null)

  const handleCookieChange: CookieChangeListener = (cookie) => {
    if (cookie.name === trackingCookieName) {
      setTrackingChoice(cookie.value)
    }
  }

  const acceptTracking = () => {
    cookies.set(trackingCookieName, 'accepted', { path: '/', expires: new Date(2099, 1, 1) })

    if (!posthog.has_opted_in_capturing()) {
      // This needs to wrapped in a timeout, otherwise the capture event won't be fired
      setTimeout(() => {
        console.log('opt in tracking')
        posthog.opt_in_capturing()
      })
    }

    posthog._start_queue_if_opted_in()

    sendBrowserEvent({
      variables: { eventName: 'tracking-banner-accepted', deviceType: isMobile() ? 'mobile' : 'desktop' },
    })
  }

  const rejectTracking = () => {
    cookies.set(trackingCookieName, 'rejected', { path: '/', expires: new Date(2099, 1, 1) })

    if (!posthog.has_opted_out_capturing()) {
      posthog.opt_out_capturing()
    }

    sendBrowserEvent({
      variables: { eventName: 'tracking-banner-rejected', deviceType: isMobile() ? 'mobile' : 'desktop' },
    })
  }

  const requestInformation = () => {
    setInformationRequested(true)
  }

  useEffect(() => {
    cookies.addChangeListener(handleCookieChange)
    return () => cookies.removeChangeListener(handleCookieChange)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies])

  useEffect(() => {
    if (trackingChoice !== 'rejected' && trackingChoice !== 'accepted') {
      sendBrowserEvent({
        variables: { eventName: 'tracking-banner-show', deviceType: isMobile() ? 'mobile' : 'desktop' },
      })
    }
  }, [sendBrowserEvent, trackingChoice])

  useGoogleTagManager(trackingChoice === 'accepted')

  if (trackingChoice === 'rejected' || trackingChoice === 'accepted') return null

  if (userAgentIsBot()) return null

  return (
    <div
      className="fixed bottom-0 left-0 right-0 border-8 border-me-purple-700 px-6 md:px-20 py-8 bg-white"
      ref={refCookieBanner}
    >
      {!informationRequested && <Autorejecter refCookieBanner={refCookieBanner} />}
      <div className="flex flex-col md:flex-row max-w-screen-xl mx-auto gap-16">
        <div className="hidden lg:flex items-center">
          <LogoCompact className="w-120" />
        </div>
        <div className="text-me-black flex flex-col text-start gap-4">
          <h1 className="text-3xl font-bold">{t('cookie_banner.header')}</h1>
          <I18nHtml i18nKey="cookie_banner.message_html" className="prose-p:py-2" />
          <div className="flex justify-between flex-col md:flex-row items-center gap-6">
            <div className="text-me-purple-600">
              <CookieBannerModal requestInformation={requestInformation} />
            </div>
            <div className="basis-1/2 flex flex-col md:flex-row gap-2">
              <button
                onClick={rejectTracking}
                className="text-me-purple-600 hover:text-white hover:bg-me-purple-600 border-2 border-me-purple-600 py-3 px-8 w-64 rounded-full"
              >
                {t('cookie_banner.reject_button_label')}
              </button>
              <button
                className="bg-me-purple-600 text-white hover:bg-me-purple-700 border-me-purple-600 bg:border-me-purple-700 border-2 py-3 px-8 w-64 rounded-full flex gap-2 justify-center shadow-button shadow-me-purple-700"
                onClick={acceptTracking}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                </svg>
                <span>{t('verbs.agree')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// component to auto-reject cookies some time after page interaction.
// must be unmounted to cancel rejection.
const Autorejecter = ({ refCookieBanner }: { refCookieBanner: React.MutableRefObject<HTMLDivElement | null> }) => {
  const [sendBrowserEvent] = useBrowserEventMutation()
  const [pageUsed, setPageUsed] = useState(false)
  const { y } = useWindowScroll()
  const refFirstScroll = useRef(true)

  // track scrolling on page
  useEffect(() => {
    if (refFirstScroll.current) {
      refFirstScroll.current = false
      return
    }
    setPageUsed((used) => used || true)
  }, [y])

  const rejectTracking = () => {
    cookies.set(trackingCookieName, 'rejected', { path: '/', expires: new Date(2099, 1, 1) })

    if (!posthog.has_opted_out_capturing()) {
      posthog.opt_out_capturing()
    }

    sendBrowserEvent({
      variables: { eventName: 'tracking-banner-rejected-by-timeout', deviceType: isMobile() ? 'mobile' : 'desktop' },
    })
  }

  // track clicks outside cookie banner
  useClickAway(refCookieBanner, () => setPageUsed((used) => used || true))

  // if either happened, start timeout to reject tracking
  useEffect(() => {
    if (!pageUsed) return
    const timer = setTimeout(() => rejectTracking(), 10000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUsed])

  return null
}

export const CookieBannerLink = ({ className, content }: { className?: string; content?: string }): JSX.Element => {
  /** @type {React.MouseEventHandler} */
  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault()
    cookies.set(trackingCookieName, 'edit', { path: '/', expires: new Date(2099, 1, 1) })
  }

  return (
    <button
      className={classNames(['text-me-purple-600 hover:underline', className, styles.cookieBannerLink])}
      onClick={handleClick}
    >
      {content}
    </button>
  )
}

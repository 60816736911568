import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'
import { I18nHtml, t } from 'shared'

export const CookieBannerModal = ({ requestInformation }: { requestInformation: () => void }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
    requestInformation()
  }

  return (
    <>
      <button onClick={handleClick}>{t('misc.more_information')}</button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
        <div className="fixed inset-0 bg-black/80" aria-hidden="true" />
        <div className="fixed inset-0 w-screen overflow-y-auto p-4">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel className="max-w-5xl space-y-4 border bg-white py-5 rounded-md">
              <DialogTitle className="font-bold text-2xl border-b px-6 pb-5 relative">
                <span>{t('cookie_banner.details_header')}</span>
                <button className="absolute top-0 right-0 px-4" onClick={() => setIsOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </button>
              </DialogTitle>
              <Description className="px-6">
                <I18nHtml
                  i18nKey="cookie_banner.details_message_html"
                  className="prose-a:text-me-purple-600 prose-a:no-underline prose prose-h2:mb-2 prose-h3:mt-4"
                />
              </Description>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}
